import { PkCordova, PkCordovaAnalytics } from 'pikkart-cordova';

export class AnalyticsRegister {
    static logEvent = (name, params) => {
        console.log('log analytics event ' + name, params);
        if (PkCordova.isCordova())
            PkCordovaAnalytics.logEvent(name, params);
    }

    static setCurrentScreen = (name) => {
        console.log('set analytics screen ', name);
        if (PkCordova.isCordova())
            PkCordovaAnalytics.setCurrentScreen(name);
    }
}

export const ANALYTICS_EVENT = {
    OPEN_CONTENT_DETAIL: 'Open_Content_Detail',
    OPEN_AR_SCENE_DETAIL: 'Open_Ar_Scene_Detail',
    OPEN_CONTENT_KML: 'Open_Content_Kml',
    ADD_TO_FAVORITES: 'Add_To_Favorites',
    SAVE_PROFILE_DATA: 'Save_Profile_Data',
    WEB_SITE_URL_CLICK: 'Click_Website_Url',
    PHONE_NUMBER_CLICK: 'Click_Phone_Number',
    EMAIL_CLICK: 'Click_Email',
    ATTACHMENT_CLICK: 'Click_Attachment',
    START_AR_SCENE: 'Start_Ar_Scene',
    SHARE_URL_CLICK: 'Click_Share_Url',
};


export const ANALYTICS_SCREEN = {
    MAIN: 'Main',
    PROFILE: 'Profile',
    CREDITS: 'Credits',
    TUTORIAL: 'Tutorial',
    NOTIFICATIONS: 'Notifications',
    CONTENT_KML: 'Content_KML',
    CONTENT_DETAIL: 'Content_Detail',
    AR_SCENE_LIST: 'Ar_Scene_List',
    LOGIN: 'Login'
};

export default AnalyticsRegister;