import React, { Component } from 'react';
import { App, View } from 'framework7-react';
import routes from './routes';
import axios from 'axios';
import { connect } from 'react-redux';
import Menu from './contents/menu-panel';
import ErrorBoundary from './error-boundary/error-boundary';

class AppBoot extends Component {

  render() {

    // Valgono per tutti i verbi: GET, POST, PUT, DELETE
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Cache-Control'] = 'no-cache';

    //initReactFastclick();

    let theme = 'auto';
    if (document.location.search.indexOf('theme=') >= 0) {
      theme = document.location.search.split('theme=')[1].split('&')[0];
    }
    const f7Params = {
      id: 'com.pikkart.dssmith',
      theme,
      routes,
      popup: {
        closeOnEscape: true,
      },
      sheet: {
        closeOnEscape: true,
      },
      popover: {
        closeOnEscape: true,
      },
      actions: {
        closeOnEscape: true,
      },
      navbar: {
        hideOnPageScroll: false,
        iosCenterTitle: true,
        mdCenterTitle: true
      },
      touch: {
        tapHold: true,
        fastClicks: false
      },
      statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
      }
    }

    return (
      <App
        id='com.pikkart.dssmith'
        theme={theme}
        routes={routes}
        popup={{ closeOnEscape: true }}
        sheet={{ closeOnEscape: true }}
        popover={{ closeOnEscape: true }}
        actions={{ closeOnEscape: true }}
        navbar={{ hideOnPageScroll: false, iosCenterTitle: true, mdCenterTitle: true }}
        touch={{ tapHold: true, fastClicks: false }}
        statusbar={{ iosOverlaysWebView: true, androidOverlaysWebView: false }}
      >
        <ErrorBoundary>
          <Menu></Menu>
          <View
            url="/"
            main
            browserHistory={false}
            browserHistoryRoot={document.location.pathname.split('index.html')[0]}>
          </View>
        </ErrorBoundary>
      </App >
    );

  }

}

// #region Redux
const mapStateToProps = state => {

  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(AppBoot);