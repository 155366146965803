import React from 'react';
import { Link, Block } from 'framework7-react';
import MapView from '../content-views/content-elements/map-view';
import ContactList from '../content-views/content-elements/contact-list';
import ImageGallery from '../content-views/content-elements/image-gallery';
// import ReactHtmlParser from 'react-html-parser';

class InfoContentView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        const content = this.props.content;

        const shareLink = (content.googleUrl && content.googleUrl !== "" ?
            <Link
                className='material-icons'
                iconIos="material:share"
                iconMd="material:share"
                color='black'
                onClick={() => { this.props.shareContentHandler(content.googleUrl) }} /> :
            "");

        return (
            <Block style={{ marginTop: 0 }}>

                <div className="video-container">

                    <div style={{ textAlign: 'right' }}>
                        {shareLink}
                    </div>
                </div>

                <div>
                    <p>{content.descr}</p>
                    {/* <p>{ReactHtmlParser(content.descr.replaceAll("\n", "<br>"))}</p> */}

                    {((content.lat !== 0 && content.long !== 0) ? <MapView content={content} /> : null)}

                    <ContactList content={content} />

                </div>


                <ImageGallery
                    imageArray={content.imageUrls}
                    galleryTitle={this.props.galleryTitle} />
            </Block>
        )
    }
};

export default InfoContentView;